<template>
    <v-container fluid fill-height>
        <v-app-bar :color="color" dark app>
            <v-app-bar-nav-icon>
                <v-img contain height="40px" src="../assets/smartchef.png"></v-img>
            </v-app-bar-nav-icon>
            <v-toolbar-title>
                SmartChef
            </v-toolbar-title>
        </v-app-bar>
        <v-layout justify-center class="bg-texture">
            <v-flex xs12 sm12 md6>
                <v-form v-model="valid" v-if="act" ref="itemForm" @submit="item_submit">
                    <v-card height="100%" elevation="5">
                        <v-card-title>
                            Crear cuenta
                        </v-card-title>
                        <v-card-text class="pt-3">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-select v-model="item.category" hide-details="auto" label="Tipo de cuenta "
                                        :items="categories" :rules="f_required"  outlined required />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select v-model="item.type_id" hide-details="auto" label="Tipo Documento"
                                        :items="type_id" :rules="f_required" outlined required />
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field v-model="item.identification_id" :rules="f_required"
                                        label="Número de documento" hide-details="auto" required outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="item.name" :rules="f_required"
                                        label="Nombre del establecimiento" hide-details="auto" required outlined>
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6">
                                    <v-combobox v-model="item.group_name" hide-details="auto" label="Ubicación"
                                        :items="groups" :rules="f_required" outlined required />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-combobox v-model="item.subgroup_name" hide-details="auto" label="Tipo de negocio"
                                        :items="subgroups" :rules="f_required" outlined required />
                                </v-col> -->
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="item.address" :rules="f_required" label="Dirección"
                                        hide-details="auto" required outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-combobox v-model="item.country" hide-details="auto" label="País"
                                        :items="countries" :rules="f_required" outlined required
                                        @change="country_change" />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-combobox v-model="item.province" hide-details="auto" label="Provincia"
                                        :items="state" :rules="f_required" outlined required
                                        @change="province_change" />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-combobox v-model="item.city" hide-details="auto" label="Ciudad" :items="city"
                                        :rules="f_required" outlined required />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="item.mobile" label="Celular" hide-details="auto" outlined
                                        append-icon="mdi-phone-outline" :rules="f_required" v-mask="'(###) ###-####'">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="item.email" :rules="f_email_val" label="Correo Electrónico"
                                        hide-details="auto" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field autocomplete="current-password" v-model="item.password" outlined
                                        label="Seleccione una clave" hint="Su clave ha pasado la validación."
                                        :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="() => (value = !value)" :type="value ? 'password' : 'text'"
                                        :rules="passwordRules" @input="_ => userPassword = _"></v-text-field>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn text dark class="warning" large to="/">
                                <v-icon>mdi-arrow-left</v-icon>
                                <span>Regrezar</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="!valid" class="success" large type="submit">
                                <span>Siguiente</span>
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-form ref="formtoken" v-else @submit="check_token">
                    <v-card elevation="0" :loading="loading_status" loader-height="10" class="mx-auto" max-width="344"
                        outlined>
                        <v-card-title>
                            Validación número de teléfono
                        </v-card-title>
                        <v-card-subtitle>
                            Hemos enviado un mensaje de texto con el código a su celular con el código de activación.
                        </v-card-subtitle>
                        <v-card-text>
                            <v-text-field v-model="act_code" :rules="codeRules" label="OTP" outlined required>
                            </v-text-field>
                            <v-card-actions>
                                <v-progress-circular :rotate="360" :size="80" :width="10" :value="countDown"
                                    color="teal">
                                    {{ countDown }}
                                </v-progress-circular>
                                <v-spacer></v-spacer>
                                <v-btn color="success" type="submit" class="mr-4">
                                    Activar
                                </v-btn>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>

        <v-snackbar v-model="snackToast.dialog" :timeout="snackToast.timeout_error" absolute centered dark
            :color="snackToast.color" elevation="24">
            {{ this.snackToast.text }}
        </v-snackbar>



    </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createStore.js";
import createWebset from "../utils/createSetting.js";
import cities_lst from "../utils/cities.js";
export default {
    name: "Registration",
    components: {},
    data() {
        return {
            storeStatus: ["QR4", "QR", "Active", "Inactive", "Hold"],
            color: "",
            saleStatus: [
                "Pendiente",
                "En fabrica",
                "Listo para visita",
                "Interesado",
                "No interesado",
            ],
            edit: false,
            menuPhoto: null,
            QRdialog: false,
            stopCamera: false,
            initCamera: false,
            result: "",
            productPhoto: null,
            viewMap: false,
            recenter: false,
            ipgeo: null,
            cities: cities_lst(),
            countries: [],
            state: [],
            city: [],
            loading_status: false,
            tab: null,
            dialog_confirm: false,
            compressed_image: null,
            compressed_menu: null,
            reg_delete: null,
            valid: false,
            f_required: [(v) => !!v || "Requerido"],
            f_email: [
                (v) => !!v || "Correo valido",
                (v) => /.+@.+/.test(v) || "Correo no valido",
            ],
            f_email_val: [
                (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "Correo no valido",
            ],
            codeRules: [
                (v) => !!v || "El código es requerido",
                (v) => v.length === 6 || "EL código debe tener 6 números].",
            ],
            passwordRules: [
                (v) =>
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&])(?=.{8,})/.test(v) ||
                    "Mínimo 8 caracteres, con al menos una letra mayuscula, un numero y un caracter especial.",
            ],
            type_id: [
                { value: "13", text: "Cédula de ciudadanía" },
                { value: "31", text: "NIT" },
                { value: "11", text: "Registro civil" },
                { value: "12", text: "Tarjeta de identidad" },
                { value: "21", text: "Tarjeta de extranjería" },
                { value: "22", text: "Cédula de extranjería" },
                { value: "41", text: "Pasaporte" },
                { value: "42", text: "Documento de identificación extranjero" },
                { value: "50", text: "NIT de otro país" },
            ],
            typeaccountsID: [
                { value: "1", text: "Persona Juridica" },
                { value: "2", text: "Persona Natural" },
            ],
            TaxLevelCodes: [
                { value: "04", text: "Simple" },
                { value: "05", text: "Ordinario" },
            ],
            stloc: null,
            fontsLst: [
                "Amatic",
                "Architects",
                "Carattere",
                "Montserrat",
                "Roboto",
                "Shadows",
            ],
            groups: ['Bodega', 'Isla Centro Comercial', 'Plaza de Comidas', 'Local', 'Local Centro Comercial', 'Oficina'],
            subgroups: ['Restaurante', 'Comida Rápida', 'Calzado', 'Ferretería', 'Farmacia', 'Miscelanea', 'Ropa', 'Celualres', 'Frutas y Verduras', 'Congelados', 'Carnes', 'Mariscos', 'Aves', 'Aseo', 'Bebidas', 'Licores', 'Papelería', 'Desechables', 'Maquinaria y Equipos', 'Lacteos', 'Heladería'],
            webSet: createWebset(),
            item: createItem(),
            act: true, act_code: "",
            countDown: 100,
            token: '',
            snackToast: {
                timeout_error: 2000,
                dialog: false,
                text: "",
                color: "success"
            },
            value: false,
            categories: [
                { value: "store", text: "Cliente mayorista" },
                { value: "supplier", text: "Proveedor mayorista" },
            ],
        };
    },
    mounted() {
        var dta = this.cities;
        const keys = Object.keys(dta).sort();
        this.countries = keys;
        this.stloc = this.item.location;
        this.groups.sort();
        this.subgroups.sort();
    },
    methods: {
        returnCap(event) {
            this.item[event.target.id] = event.target.value.toUpperCase();
        },
        onlyNumber($event) {
            var keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        getImagePhoto: function (account, code, src) {
            var url =
                "https://smartchef.pro/photos/" + account + "/" + code + "/" + src;
            if (this.compressed_image) url = this.compressed_image;
            return url;
        },
        getImageMenu: function (account, code, src) {
            var url =
                "https://smartchef.pro/menus/" + account + "/" + code + "/" + src;
            if (this.compressed_menu) url = this.compressed_menu;
            return url;
        },
        chooseImage() {
            this.$refs.fileInput.click();
        },
        chooseMenu() {
            this.$refs.menuInput.click();
        },
        openURL() {
            window.open(this.item.qr, "", "width=400,height=800");
        },
        onSelectFile() {
            const input = this.$refs.fileInput;
            const files = input.files;
            if (files && files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    var img = new Image();
                    img.onload = () => {
                        var prct = 800 / img.width;
                        const elem = document.createElement("canvas");
                        elem.width = img.width * prct;
                        elem.height = img.height * prct;
                        const ctx = elem.getContext("2d");
                        ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
                        var imageUrl = elem.toDataURL("image/jpeg");
                        this.compressed_image = imageUrl;
                        this.productPhoto = imageUrl;
                        //this.$emit("compressed-image", imageUrl);
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(files[0]);
                this.item.picture = files[0].name.toLowerCase();
                //this.$emit("input", files[0].name.toLowerCase());
            }
        },
        onSelectMenu() {
            const input = this.$refs.menuInput;
            const files = input.files;
            if (files && files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    var img = new Image();
                    img.onload = () => {
                        var prct = 800 / img.width;
                        const elem = document.createElement("canvas");
                        elem.width = img.width * prct;
                        elem.height = img.height * prct;
                        const ctx = elem.getContext("2d");
                        ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
                        var imageUrl = elem.toDataURL("image/jpeg");
                        this.compressed_menu = imageUrl;
                        this.menuPhoto = imageUrl;
                        //this.$emit("compressed-image", imageUrl);
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(files[0]);
                this.item.menus = files[0].name.toLowerCase();
                //this.$emit("input", files[0].name.toLowerCase());
            }
        },
        item_submit(e) {
            e.preventDefault();
            this.valid = this.$refs.itemForm.validate();
            if (this.valid) {
                var qry = {
                    phone: this.item.mobile,
                    type_id: this.item.type_id,
                    identification_id: this.item.identification_id,
                };
                webserver("get_token", qry, (data) => {
                    if (data.token) {
                        this.token = data.token;
                        this.act = false;
                        this.countDown = 100;
                        this.countDownTimer();
                    } else {
                        this.snackToast = {
                            dialog: true,
                            text: "No podemos procesar su registro. El número de documento ya existe.",
                            color: "red"
                        }
                    }

                });
            }
        },
        delete_itm(data) {
            this.reg_delete = data.code;
            this.dialog_confirm = true;
        },
        delete_item() {
            var record = this.reg_delete;
            this.loading_status = true;
            var qry = {
                table: this.table,
                filters: {
                    account: this.$store.state.profile.account,
                    code: record,
                },
            };
            webserver("delete_record", qry, () => {
                this.reg_delete = null;
                this.dialog_confirm = false;
                this.loading_status = false;
                this.$emit("refresh", true);
            });
        },
        close() {
            this.$emit("close", true);
        },
        call_contact(data) {
            window.location.href = "tel://" + data;
        },
        country_change(e) {
            this.state = [];
            this.city = [];
            this.item.province = null;
            this.item.city = null;
            var stt = this.cities[e];
            this.state = Object.keys(stt).sort();
        },
        province_change(e) {
            this.city = [];
            this.item.city = null;
            this.city = this.cities[this.item.country][e].sort();
        },
        updateLocation(e) {
            this.item.location = JSON.stringify(e);
        },
        onDecode(e) {
            this.stopCamera = true;
            this.QRdialog = false;
            this.item.qr = e;
        },
        openQR() {
            this.stopCamera = false;
            this.QRdialog = true;
        },
        onLoaded(e) {
            console.log(e);
        },
        getLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (res) => {
                            resolve([res.coords.latitude, res.coords.longitude]);
                        },
                        (err) => {
                            console.log(err);
                            reject(err);
                        }
                    );
                } else {
                    reject("No Geolocation support.");
                }
            });
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer()
                }, 500)
            }
        },
        check_token(e) {
            e.preventDefault();
            this.loading_status = true;
            var md5 = require("md5");
            if (md5(this.act_code) === this.token) {
                this.item.agent = "SmartChef";
                this.item.status = "Inactive";
                this.item.account = "575002";
                var qry = {
                    account: '',
                    table: "stores",
                    data: [this.item],
                };
                if (this.compressed_image) {
                    qry.photo = this.compressed_image;
                }
                console.log(qry);
                webserver("create_store", qry, () => {
                    this.compressed_image = null;
                    this.compressed_menu = null;
                    this.loading_status = false;
                    this.$router.push("Ingreso");

                    this.snackToast = {
                        dialog: true,
                        text: "Sus datos han sido registrados con EXITO !",
                        color: "success"
                    }
                });
            } else {
                this.snackToast = {
                    dialog: true,
                    text: "El código ingresado no es correcto.",
                    color: "warning"
                }
            }
        },
    },
    watch: {
        countDown: function (value) {
            if (value === 0) {
                this.act = true;
            }
        }
    },
};
</script>

<style>
.v-main {
    padding: 0px 0px 0px !important;

}

.container {
    padding: 0px;
}

.bg-texture {
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.607)), url("../assets/bg.png") no-repeat center center fixed;

    /* background: rgba(0, 0, 0, 0.61); */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    padding: 128px 0px 0px !important;
}

.banner {
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.607));
}

.regForm {
    background: rgba(0, 0, 0, 0.342);
}

.v-application .error--text {
    color: #f1252575 !important;
}

.base-image-input {
    display: block;
    width: 100%;
    height: 250px;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
}


.file-input {
    display: none;
}

.btn-upload {
    top: 150px;
}
</style>